import React from "react";
import {
  List,
  Datagrid,
  NumberField,
  TextField,
  Edit,
  Create,
  TabbedForm,
  ArrayInput,
  TextInput,
  RadioButtonGroupInput,
  ReferenceInput,
  SelectInput,
  Button,
  EditButton,
  SimpleFormIterator,
  AutocompleteInput,
  CheckboxGroupInput,
  useRecordContext,
  required,
  FormDataConsumer,
  regex,
} from "react-admin";
import { Link } from "react-router-dom";

import ArrowForwardIcon from "@mui/icons-material//ArrowForward";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

const validateRegExp = (value) => {
  try {
    new RegExp(value);
  } catch (error) {
    return "" + error;
  }
  return undefined;
};

const LoginButton = () => {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <Button label="Login" component={Link} to={`/c/${record.id}/`}>
      <ArrowForwardIcon />
    </Button>
  );
};

const CustomerAppBarTitle = () => {
  const record = useRecordContext();
  if (!record) return null;
  return <span> {"Customer #" + record.id + ": " + record.longName}</span>;
};

const SelectBillingStatus = ({ allowMulti, ...props }) => {
  const Elem = allowMulti ? CheckboxGroupInput : RadioButtonGroupInput;

  return (
    <Elem
      {...props}
      choices={[
        { id: "subscribed", name: "Subscribed" },
        { id: "internal", name: "Internal" },
        { id: "dormant", name: "Dormant" },
      ]}
    />
  );
};

const customerFilters = [
  <SelectBillingStatus
    label="Status"
    source="billingStatus"
    allowMulti
    style={{ marginBottom: 0, flexDirection: "row" }}
  />,
];

export const CustomerList = () => (
  <List
    pagination={null}
    filters={customerFilters}
    filterDefaultValues={{ billingStatus: ["subscribed", "internal"] }}
    storeKey={false}
  >
    <Datagrid bulkActionButtons={false}>
      <NumberField source="id" label="ID" sortable={false} />
      <TextField source="shortName" label="Short Name" sortable={false} />
      <TextField source="longName" label="Long Name" sortable={false} />
      <TextField source="region" label="Region" sortable={false} />
      <TextField source="billing.status" label="Status" sortable={false} />
      <EditButton />
      <LoginButton />
    </Datagrid>
  </List>
);

const AccessLevel = [
  { id: "member", name: "member" },
  { id: "manager", name: "manager" },
];

const UserAssociationInput = () => {
  const record = useRecordContext();
  return (
    <ArrayInput source="userAssociations">
      <SimpleFormIterator source="users" inline>
        {record ? (
          <NumberField
            source="customerID"
            defaultValue={record.id}
            hidden
          ></NumberField>
        ) : null}
        <ReferenceInput
          source="userID"
          reference="users"
          sort={{ field: "email", order: "ASC" }}
        >
          <AutocompleteInput
            optionText="email"
            filterToQuery={(searchText) => ({ email: searchText })}
            label="User"
            style={{ width: "400px" }}
          />
        </ReferenceInput>
        <SelectInput
          source="access"
          choices={AccessLevel}
          label="Access Level"
          defaultValue={"member"}
          validate={required()}
        />
      </SimpleFormIterator>
    </ArrayInput>
  );
};

const TaskType = [
  { id: "inbound", name: "Inbound" },
  { id: "outbound_virtual_composite", name: "Outbound - Virtual Composite" },
];
const validateCron = regex(
  /^(?:\d{1,2}|\*) (?:\d{1,2}|\*) (?:\d{1,2}|\*) (?:\d{1,2}|\*) (?:\d{1,2}|\*)$/,
  "Must be a valid cron expression"
);
const ScheduleField = () => (
  <>
    <div style={{ display: "flex", alignItems: "center" }}>
      <h3 style={{ marginRight: "10px" }}>Schedule</h3>
      <Tooltip title="Cron" placement="top">
        <IconButton
          href="https://en.wikipedia.org/wiki/Cron"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InfoIcon />
        </IconButton>
      </Tooltip>
    </div>
    <ArrayInput source="integration.schedule" label="">
      <SimpleFormIterator inline disableReordering>
        <SelectInput source="type" label="Type" choices={TaskType} />
        <TextInput source="cron" label="Cron" validate={validateCron} />
      </SimpleFormIterator>
    </ArrayInput>
  </>
);

const IntegrationSetting = () => {
  return (
    <>
      <SelectInput
        label="Type"
        source="integration.type"
        choices={[
          { id: "innovint", name: "InnoVint" },
          { id: "vintrace", name: "Vintrace" },
        ]}
      />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          if (formData.integration?.type === "innovint")
            return (
              <>
                <TextInput
                  source="integration.innoVintPublicWineryId"
                  label="Public Winery ID"
                  style={{ width: "300px" }}
                />
                <TextInput
                  source="integration.innoVintPublicOrgId"
                  label="Public Organization ID (required for Lot Intended Use)"
                  style={{ width: "300px" }}
                />
                <TextInput
                  source="integration.innoVintPAT"
                  label="Personal Access Token (PAT)"
                  style={{ width: "300px" }}
                />
                <ScheduleField />
              </>
            );
          else if (formData.integration?.type === "vintrace")
            return (
              <>
                <TextInput
                  source="integration.vintraceBaseUrl"
                  label="Base URL"
                  placeholder="https://abc123.vintrace.net"
                  style={{ width: "300px" }}
                />
                <TextInput
                  source="integration.vintraceCustomerCode"
                  label="Customer Code"
                  style={{ width: "300px" }}
                />
                <TextInput
                  source="integration.vintraceAPIToken"
                  label="API Token"
                  style={{ width: "300px" }}
                />
                <TextInput
                  source="integration.vintraceBusinessUnit"
                  label="Business Unit (optional)"
                  placeholder="US42"
                  style={{ width: "300px" }}
                />
                <ScheduleField />
              </>
            );
        }}
      </FormDataConsumer>
    </>
  );
};

const CreateEditForm = ({ disableShortname, ...props }) => (
  <TabbedForm {...props}>
    <TabbedForm.Tab label="General">
      <TextInput label="ID" source="id" disabled />
      <TextInput
        label="Short Name"
        source="shortName"
        disabled={disableShortname}
      />
      <TextInput label="Long Name" source="longName" />
      <TextInput label="Region" source="region" />
    </TabbedForm.Tab>
    <TabbedForm.Tab label="Billing">
      <SelectBillingStatus label="Status" source="billing.status" />
      <TextInput multiline fullWidth label="Notes" source="billing.notes" />
    </TabbedForm.Tab>
    <TabbedForm.Tab label="Preferences">
      <TextInput
        fullWidth
        label="Barcode RegEx"
        source="preferences.barcodeRegex"
        validate={validateRegExp}
      />
      <RadioButtonGroupInput
        label="Temperature Unit"
        source="preferences.temperatureUnit"
        choices={[
          { id: "C", name: "°C" },
          { id: "F", name: "°F" },
        ]}
      />
      <RadioButtonGroupInput
        label="Volume Unit"
        source="preferences.volumeUnit"
        choices={[
          { id: "L", name: "Litre" },
          { id: "Gal", name: "Gallon" },
        ]}
      />
    </TabbedForm.Tab>
    <TabbedForm.Tab label="Integration">
      <IntegrationSetting />
    </TabbedForm.Tab>
    <TabbedForm.Tab label="Users">
      <UserAssociationInput />
    </TabbedForm.Tab>
  </TabbedForm>
);

export const CustomerEdit = () => (
  <Edit redirect="list" title={<CustomerAppBarTitle />}>
    <CreateEditForm disableShortname={true} />
  </Edit>
);

export const CustomerCreate = () => (
  <Create redirect="list">
    <CreateEditForm />
  </Create>
);
